import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Navbar, Nav, NavItem} from 'react-bootstrap' 
// import { Location } from '@reach/router';
import { Location } from '@reach/router'

import { withPrefix } from "gatsby"


class Header extends React.Component {
render  () {
// const Header = ({ siteTitle, location }) => {
  
  const { siteTitle, location } = this.props

  // const isHomepage = location === withPrefix("/how")
  // console.log(location)
  // className={location.pathname === '/' ? 'red' : 'black'}

return (
<Location>
{({ location }) => (
  
  <Navbar bg="none"  expand="md"  fixed="top">

  <Navbar.Brand as={Link} href="/" className= {location.pathname === '/about' ? 'red pr-3' : 'dark-green pr-3'} >
    {siteTitle}
  </Navbar.Brand>

  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">

    <Nav className="mr-auto">

      <NavItem href="/how">
        <Nav.Link as={Link} activeClassName="active" to="/how">
          <div className={location.pathname === '/about' ? 'red' : 'dark-green'}>
          How to
            </div>
        </Nav.Link>
      </NavItem>

      <NavItem href="/ideas">
        <Nav.Link as={Link} activeClassName="active" to="/ideas">
        <div className={location.pathname === '/about' ? 'red' : 'dark-green'}>
          Ideas
          </div>
        </Nav.Link>
      </NavItem>

      <NavItem href="/yourown">
        <Nav.Link as={Link} activeClassName="active" to="/yourown">
        <div className={location.pathname === '/about' ? 'red' : 'dark-green'}>
          Write your own
          </div>
        </Nav.Link>
      </NavItem>

    {/* <NavItem href="/videos">
        <Nav.Link as={Link} activeClassName="active" to="/videos">
          Videos
        </Nav.Link>
      </NavItem> */}

      <NavItem href="/about">
        <Nav.Link as={Link} activeClassName="active" to="/about">
        <div className={location.pathname === '/about' ? 'red' : 'dark-green'}>
          About
          </div>
        </Nav.Link>
      </NavItem>


    </Nav>
  </Navbar.Collapse>
</Navbar>

)}
</Location>


)  }}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
